<template>
    <div class="app-container">
        <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
            <el-tab-pane :label="$t('template.templateTabName')" :name="templateTabName" :key="templateTabName">
                <el-row>
                    <el-col :span="2">
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="addTemplate">
                            {{$t('template.addTemplate')}}
                        </el-button>
                    </el-col>
                    <el-col :span="6" :offset="16">
                        <div class="filter-container" align="right">
                            <el-button class="filter-item" type="primary" size="small" icon="el-icon-refresh"
                                       :loading="loading" @click="getTemplateList">
                                {{$t('commons.refresh') }}
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-table v-loading="loading"
                          :data="template.list"
                          element-loading-text="Loading"
                          border
                          fit
                          highlight-current-row
                          stripe>
                    <el-table-column type="index" align="center" :label="$t('commons.index')" width="95">
                    </el-table-column>
                    <el-table-column :label="$t('template.templateName')" width="220" :show-overflow-tooltip='true'>
                        <template slot-scope="scope">
                            <template v-if="scope.row.edit">
                                <el-input v-model="scope.row.templateName" maxlength="80" :show-word-limit="true" size="small" clearable
                                          :placeholder="$t('commons.pleaseInput')"/>
                            </template>
                            <span v-else>{{ scope.row.templateName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('template.comment')" :show-overflow-tooltip='true'>
                        <template slot-scope="scope">
                            <template v-if="scope.row.edit">
                                <el-input v-model="scope.row.comment" maxlength="160" :show-word-limit="true" size="small" clearable
                                          :placeholder="$t('commons.pleaseInput')"/>
                            </template>
                            <span v-else>{{ scope.row.comment }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('template.totalPrice')" align="right" width="120">
                        <template slot-scope="scope">
                            <template v-if="scope.row.totalPrice > 0">
                                <span>¥{{ scope.row.totalPrice }}</span>
                            </template>
                            <span v-else>{{ $t('commons.free') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="creationDate" align="center" :label="$t('commons.creationTime')" width="220">
                        <template slot-scope="scope">
                            <span>{{ scope.row.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" :label="$t('commons.type')" width="120">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.templateId < 0" type="success">{{ $t('template.type1') }}</el-tag>
                            <el-tag v-else>{{ $t('template.type2') }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" :label="$t('commons.actions')" width="145">
                        <template slot-scope="scope">
                            <template v-if="scope.row.edit">
                                <div class="ruge-buttons">
                                    <a class="ruge-button" @click="updateTemplate(scope.row)"
                                       :title="$t('commons.save')"><i class="el-icon-circle-check"></i></a>
                                    <a class="ruge-button" @click="cancelTemplate(scope.row)"
                                       :title="$t('commons.cancel')"><i class="el-icon-circle-close"></i></a>
                                </div>
                            </template>
                            <template v-else>
                                <div class="ruge-buttons">
                                    <a class="ruge-button" @click="editTemplate(scope.row)" :title="$t('commons.edit')"><i
                                            class="el-icon-edit-outline"></i></a>
                                    <a class="ruge-button" @click="layoutModule(scope.row)"
                                       :title="$t('tenant.module')"><i class="el-icon-box"></i></a>
                                    <template v-if="scope.row.templateId > 0">
                                        <a class="ruge-button" @click="deleteTemplate(scope.row)" :title="$t('commons.delete')"><i
                                                class="el-icon-delete"></i></a>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-dialog class="app-dialog" :visible.sync="template.addVisible" width="400px">
                    <template slot="title">
                        <title-icon/>
                        {{ $t('template.addTemplate') }}
                    </template>
                    <el-form :model="template.form" ref="templateForm" :rules="template.formRule" label-width="80px">
                        <el-form-item :label="$t('template.templateName')" prop="templateName">
                            <el-input v-model="template.form.templateName" maxlength="80" :show-word-limit="true" name="templateName" type="text"
                                      auto-complete="on"
                                      :placeholder="$t('commons.pleaseInput')" clearable autofocus>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('template.comment')" prop="comment">
                            <el-input v-model="template.form.comment" maxlength="160" :show-word-limit="true" name="comment" type="text" auto-complete="on"
                                      :placeholder="$t('commons.pleaseInput')" clearable autofocus>
                            </el-input>
                        </el-form-item>

                    </el-form>
                    <div slot="footer">
                        <el-button @click="quitTemplate()">{{$t('commons.cancel') }}</el-button>
                        <el-button type="primary" @click="saveTemplate()">{{$t('commons.save') }}</el-button>
                    </div>
                </el-dialog>
            </el-tab-pane>
            <el-tab-pane v-if="choiceModuleTab" :label="$t('tenant.selectModule')" :name="choiceModuleTab.tabName"
                         :key="choiceModuleTab.tabName" closable>
                <el-tag>{{ $t('template.templateName') }}: {{ currentRow.templateName }}</el-tag>
                <template-modulx v-bind:currentRow="currentRow" :key="currentRow.templateId"></template-modulx>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

    let templatexApi = require('@/api/ruge/tenant/templatex')
    import TemplateModulx from '@/views/ruge/modulx/templateModulx'

    export default {
        components: {TemplateModulx},
        data() {
            return {
                activeTabName: "templateTab",
                templateTabName: "templateTab",
                choiceModuleTab: null,
                loading: true,
                template: {
                    addVisible: false,
                    list: [],
                    form: {
                        templateName: null,
                        comment: null
                    },
                    formRule: {}
                },
                currentRow: {
                    templateId: null,
                    templateName: ''
                },
                elCardIcon: {
                    "background": "url(" + require('@/assets/images/lineIcon.png') + ") center right no-repeat",
                },
                elCardActiveSrc: require('@/assets/images/el-card-active.png'),
            };
        },
        created() {
            this.getTemplateList()
        },
        methods: {
            getTemplateList() {
                this.loading = true;
                templatexApi.getTemplateList().then(rsp => {
                    this.template.list = rsp.map(v => {
                        v.totalPrice = v.totalPrice.toFixed(2);
                        this.$set(v, 'edit', false)
                        this.setOrgTemplate(v);
                        return v
                    })
                }).finally(() => {
                    this.loading = false;
                })
            },
            setOrgTemplate(row) {
                row.orgTemplateName = row.templateName;
            },
            resetOrgTemplate(row) {
                row.templateName = row.orgTemplateName;
            },
            cancelTemplate(row) {
                this.resetOrgTemplate(row);
                row.edit = false;
            },
            editTemplate(row) {
                row.edit = true;
            },
            addTemplate() {
                this.template.addVisible = true;
            },
            quitTemplate() {
                this.template.addVisible = false;
            },
            layoutModule(row) {
                this.currentRow.templateId = row.templateId;
                this.currentRow.templateName = row.templateName;
                this.choiceModuleTab = {tabName: "moduleTab"};
                this.activeTabName = "moduleTab";
            },
            removeTab() {
                this.choiceModuleTab = null;
                this.activeTabName = "templateTab";
            },
            saveTemplate() {
                this.$refs.templateForm.validate((valid) => {
                    if (valid) {
                        templatexApi.createTemplate(this.template.form).then(() => {
                            this.template.form.tempalteName = null;
                            this.template.addVisible = false;
                            this.getTemplateList()
                            this.$message({type: 'success', message: this.$t('message.saveSuccess')});
                        }).catch(() => {
                            this.template.addVisible = false;
                        })
                    }
                })
            },
            deleteTemplate(row) {
                this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    // 调用服务
                    templatexApi.deleteTemplate(row).then(() => {
                        // 成功提示
                        this.$message({
                            type: 'success',
                            message: this.$t('message.deleteSuccess')
                        });
                        // 刷新列表
                        this.getTemplateList()
                    }).catch(() => {
                    })
                }).catch(error => {
                    console.log(`未删除，原因 => ${error}`)
                });
            },
            updateTemplate(row) {
                // 模版名称不能为空
                if (!row.templateName || row.templateName.trim() === '') {
                    this.$message({type: 'warning', message: this.$t('template.message.notNull')});
                    return;
                }
                templatexApi.updateTemplate(row).then(() => {
                    row.edit = false
                    this.setOrgTemplate(row);
                    this.$message({type: 'success', message: this.$t('message.saveSuccess')});
                }).catch(error => {
                    console.log(`保存失败，原因 => ${error}`)
                })
            },
        }
    }
</script>
