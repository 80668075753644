import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

//获取模版列表
export function getTemplateList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/findTemplateList',
    method: 'get',
    params
  })
}

//创建模版
export function createTemplate(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/create',
    method: 'post',
    data: params
  })
}

//删除模版
export function deleteTemplate(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/delete',
    method: 'post',
    data: params
  })
}

//更新模版
export function updateTemplate(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/update',
    method: 'post',
    data: params
  })
}

//获取挂载模块
export function getTemplateModuleList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/finMountdModuleList',
    method: 'get',
    params
  })
}

//创建挂载模块
export function createTemplateModule(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/createTemplateModule',
    method: 'post',
    data: params
  })
}

//创建挂载模块
export function updateTemplateModule(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/template/uptTemplateModule',
    method: 'post',
    data: params
  })
}


