var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.templateTabName,
              attrs: {
                label: _vm.$t("template.templateTabName"),
                name: _vm.templateTabName,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "small",
                          },
                          on: { click: _vm.addTemplate },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("template.addTemplate")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 6, offset: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "filter-container",
                        attrs: { align: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-refresh",
                              loading: _vm.loading,
                            },
                            on: { click: _vm.getTemplateList },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("commons.refresh")) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.template.list,
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    stripe: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      label: _vm.$t("commons.index"),
                      width: "95",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("template.templateName"),
                      width: "220",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.edit
                              ? [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "80",
                                      "show-word-limit": true,
                                      size: "small",
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "commons.pleaseInput"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.templateName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "templateName", $$v)
                                      },
                                      expression: "scope.row.templateName",
                                    },
                                  }),
                                ]
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.templateName)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("template.comment"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.edit
                              ? [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "160",
                                      "show-word-limit": true,
                                      size: "small",
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "commons.pleaseInput"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.comment,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "comment", $$v)
                                      },
                                      expression: "scope.row.comment",
                                    },
                                  }),
                                ]
                              : _c("span", [_vm._v(_vm._s(scope.row.comment))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("template.totalPrice"),
                      align: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.totalPrice > 0
                              ? [
                                  _c("span", [
                                    _vm._v("¥" + _vm._s(scope.row.totalPrice)),
                                  ]),
                                ]
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.$t("commons.free"))),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creationDate",
                      align: "center",
                      label: _vm.$t("commons.creationTime"),
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.creationDate,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.$t("commons.type"),
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.templateId < 0
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(_vm._s(_vm.$t("template.type1"))),
                                ])
                              : _c("el-tag", [
                                  _vm._v(_vm._s(_vm.$t("template.type2"))),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.$t("commons.actions"),
                      width: "145",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.edit
                              ? [
                                  _c("div", { staticClass: "ruge-buttons" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("commons.save"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateTemplate(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-circle-check",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("commons.cancel"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelTemplate(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-circle-close",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "ruge-buttons" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ruge-button",
                                          attrs: {
                                            title: _vm.$t("commons.edit"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editTemplate(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ruge-button",
                                          attrs: {
                                            title: _vm.$t("tenant.module"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.layoutModule(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-box",
                                          }),
                                        ]
                                      ),
                                      scope.row.templateId > 0
                                        ? [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "ruge-button",
                                                attrs: {
                                                  title:
                                                    _vm.$t("commons.delete"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteTemplate(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                }),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "app-dialog",
                  attrs: { visible: _vm.template.addVisible, width: "400px" },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.template, "addVisible", $event)
                    },
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("title-icon"),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("template.addTemplate")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "templateForm",
                      attrs: {
                        model: _vm.template.form,
                        rules: _vm.template.formRule,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("template.templateName"),
                            prop: "templateName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "80",
                              "show-word-limit": true,
                              name: "templateName",
                              type: "text",
                              "auto-complete": "on",
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                              autofocus: "",
                            },
                            model: {
                              value: _vm.template.form.templateName,
                              callback: function ($$v) {
                                _vm.$set(_vm.template.form, "templateName", $$v)
                              },
                              expression: "template.form.templateName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("template.comment"),
                            prop: "comment",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "160",
                              "show-word-limit": true,
                              name: "comment",
                              type: "text",
                              "auto-complete": "on",
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                              autofocus: "",
                            },
                            model: {
                              value: _vm.template.form.comment,
                              callback: function ($$v) {
                                _vm.$set(_vm.template.form, "comment", $$v)
                              },
                              expression: "template.form.comment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.quitTemplate()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveTemplate()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.save")))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.choiceModuleTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.choiceModuleTab.tabName,
                  attrs: {
                    label: _vm.$t("tenant.selectModule"),
                    name: _vm.choiceModuleTab.tabName,
                    closable: "",
                  },
                },
                [
                  _c("el-tag", [
                    _vm._v(
                      _vm._s(_vm.$t("template.templateName")) +
                        ": " +
                        _vm._s(_vm.currentRow.templateName)
                    ),
                  ]),
                  _c("template-modulx", {
                    key: _vm.currentRow.templateId,
                    attrs: { currentRow: _vm.currentRow },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }