<template>
    <div v-loading="loading">
        <div class="ruge-singleBtn" v-if="moduleList.length > 0">
            <el-button class="ruge-singleBtn-s" size="mini" @click="fullChoice()">
                {{$t('commons.fullChoice')}}
            </el-button>
            <el-button class="ruge-singleBtn-s" size="mini" @click="unFullChoice()">
                {{$t('commons.unFullChoice')}}
            </el-button>
            <el-button class="ruge-singleBtn-s" type="primary" size="mini" @click="saveModule()">
                {{$t('commons.save')}}
            </el-button>
        </div>
        <el-row>
            <template v-if="moduleList.length > 0">
                <el-col class="ruge-cards" :span="5" v-for="(modulx, index) in moduleList" :key="modulx.moduleCode"
                        :offset="index % 4==0 ? 0 : 1">
                    <el-card :class="modulx.choice" @click.native="choiceCard(modulx)">
                        <div :style="elCardIcon" slot="header">
                            <title-icon/><span>{{modulx.moduleName}}</span>
                        </div>
                        <div>
                            <template v-if="modulx.riseModule == 'Y'">
                                <span>{{$t('module.riseModule')}}</span>
                                <span style="float: right;">¥{{modulx.price}}/{{$t('module.unitPrice' + modulx.priceUnit)}}</span>
                                <div class="bottom" @click.stop>
                                    <span style="line-height: 28px;">{{$t('module.duration')}}</span>
                                    <span style="float: right;"><el-input-number style="width: 112px;margin-right: 2px;" v-model="modulx.unitPcs" controls-position="right" :min="1" size="mini"></el-input-number>{{$t('module.duration' + modulx.priceUnit)}}</span>
                                </div>
                            </template>
                            <template v-else>
                                <span>{{$t('module.freeModule')}}</span>
                                <div class="bottom">
                                    <span style="line-height: 28px;">{{$t('module.freeTips')}}</span>
                                </div>
                            </template>
                        </div>
                        <img class="elCardActive" :src='elCardActiveSrc'>
                    </el-card>
                </el-col>
            </template>
            <template v-else>
                <div class="no-data">{{$t('commons.noData')}}</div>
            </template>
        </el-row>
    </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }
</style>

<script>

    let templatexApi = require('@/api/ruge/tenant/templatex')
    let modulxApi = require('@/api/ruge/tenant/modulx')

    export default {
        data() {
            return {
                loading: true,
                modulxChoiceI18n: this.$t("module.choice"),
                modulxDrawer: false,
                moduleCodes: [],
                moduleList: [],
                elCardIcon: {
                    "background": "url(" + require('@/assets/images/el-card-icon.png') + ") center right no-repeat",
                },
                elCardActiveSrc: require('@/assets/images/el-card-active.png'),
            };
        },
        props: {
            currentRow: {
                type: Object,
                required: true
            }
        },
        created() {
            this.getMountModuleList()
        },
        methods: {
            getMountModuleList() {
                this.loading = true;
                // 获取当前套餐下的模块列表
                templatexApi.getTemplateModuleList({templateId: this.currentRow.templateId}).then(response => {
                    let hasModule = new Map();
                    if (response) {
                        response.map(m => {
                            hasModule.set(m.moduleCode, m);
                        });
                    }
                    // 获取所有模块
                    modulxApi.getModuleList().then((resp) => {
                        this.moduleList = resp.map(r => {
                            r.moduleName = r.moduleName.length > 15 ? r.moduleName.substring(0, 15) + "..." : r.moduleName;
                            let cr = hasModule.get(r.moduleCode);
                            if (cr) {
                                r.choice = 'choice';
                                r.unitPcs = cr.unitPcs;
                            } else {
                                r.choice = ''
                                r.unitPcs = 1;
                            }
                            return r
                        });
                    }).catch(() => {

                    })
                }).finally(() => {
                    this.loading = false;
                })
            },
            choiceCard(role) {
                if(role.choice) {
                    this.$set(role, 'choice', '');
                } else {
                    this.$set(role, 'choice', 'choice');
                }
            },
            // 全选
            fullChoice() {
                this.moduleList.map(m => {
                    this.$set(m, 'choice', 'choice');
                })
            },
            // 取消全选
            unFullChoice() {
                this.moduleList.map(m => {
                    this.$set(m, 'choice', '');
                })
            },
            saveModule() {
                let temp = this.moduleList.filter(m => {
                    return m.choice
                });
                let modules = new Array();
                temp.map(m => {
                    if (!m.unitPcs) {
                        // 使用时长不能为空
                        this.$message({type: 'error', message: this.$t('module.durationTips')});
                        modules = new Array();
                        return;
                    }
                    modules.push({
                        moduleCode: m.moduleCode,
                        templateId: this.currentRow.templateId,
                        unitPcs: m.unitPcs
                    })
                })
                if ((temp.length == 0) || (modules.length > 0)) {
                    this.loading = true
                    templatexApi.createTemplateModule({templateId:this.currentRow.templateId, moduleTemplateList: modules}).then((r) => {
                        if (r > 0) {
                            this.$message({type: 'success', message: this.$t('message.saveSuccess')});
                        }
                    }).catch(error => {
                        console.log(`保存失败，原因 => ${error}`)
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>
